<template>
    <div>
        <!-- Deals-search -->
        <div class="lead-search-area">
            <div class="row">
                <div class="col-sm-12 col-md-4 col-lg-4">
                    <div class="lead-search">
                        <div class="form-group">
                            <label>Agents</label>
                            <select class="form-control" v-model="user_id" v-on:change="reloadTable">
                                <option value="0">All</option>
                                <option v-for="employee in employees" :key="employee.id" :value="employee.user.id">{{employee.user.name}}</option>
                            </select>
                        </div>
                    </div>
                </div>
                <div class="col-sm-12 col-md-8 col-lg-8">
                    <router-link :to="{name:'addNewDeal'}" class="button rem-btn float-right">Add New Deal <fa class="ml-2" icon="plus"/></router-link>
                </div>
            </div>
        </div>
        <!-- Deals-search -->

        <!-- Deals-table-area  -->
        <div class="leads-table-area addcustomer-table">
            <DataTable :ajax='dtAjax' :options="options" width="100%" class="display" ref="table">
                <thead>
                    <tr>
                        <th>ID</th>
                        <th style="width:100px">Banner</th>
                        <th>Title</th>
                        <th>Professional</th>
                        <th>Discount</th>
                        <th>Services</th>
                        <th>Start Date</th>
                        <th>Expire Date</th>
                        <th>Status</th>
                        <th>Action</th>
                    </tr>
                </thead>
            </DataTable>
        </div>
        <!-- Deals-table-area  -->
    </div>
</template>

<script setup>
    import { ref,onMounted,inject } from 'vue'
    import $ from "jquery";
    import axios from "axios";
    import { useEmployeesStore } from '@/stores/employees'
    import { useDealsStore } from '@/stores/deals'
    import { useRouter } from "vue-router";
    import { useToast } from "vue-toastification";
    import DataTable from 'datatables.net-vue3';
    import DataTablesLib from "datatables.net";
    import "datatables.net-responsive";
    import "datatables.net-dt/css/jquery.dataTables.min.css";
    import "datatables.net-responsive-dt/css/responsive.dataTables.min.css";


    DataTable.use(DataTablesLib);
    const store = useDealsStore()
    const employeeStore = useEmployeesStore()
    const router = useRouter()
    const toast = useToast();
    const swal = inject('$swal')
    const employees = ref([])
    const user_id = ref(0)
    const table = ref();

    // DataTable configuration
    let dt;
    const dtAjax= {
        url: axios.defaults.baseURL + "dt_deals",
        type: "POST",
        data: function (d) {
            d.user_id = user_id.value;
        },
        headers: {
            Authorization: axios.defaults.headers.common['Authorization'],
        },
    }
    const options = {
        responsive: true,
        fixedHeader: true,
        processing: true,
        serverSide: true,
        columnDefs: [
            {
                targets: [3, 4, 5],
                orderable: false,
            },
        ]
    }

    // Update status
    const updateDealStatus = async (slug,status) => {
        let statusStr = status == 1 ? 'In Active':'Active'
        // let thiss = this
        swal({
            title: 'Are you sure?',
            text: "You want to change the status of this deal to "+statusStr,
            showCancelButton: true,
            confirmButtonText: 'Yes, do it!',
            reverseButtons: true,
            customClass: {
                confirmButton: 'rem-btn',
                cancelButton: 'add-btn mr-3'
            },
            buttonsStyling: false
        }).then(async (result) => {
            if (result.isConfirmed) {
                const resp = await store.updateStatus(slug,status)
                //console.log(resp)
                if (resp.status === 'success') {
                    toast.success("Status successfully update");
                    reloadTable()
                }else{
                    toast.error(resp.message);
                }
            }
        })
    }

    const getEmployees = async () => {
        const resp = await employeeStore.getEmployees()
        // console.log(resp)
        if (resp.status === 'success') {
            employees.value = resp.data
        }
    }
    getEmployees()

    const reloadTable = async () => {
        // console.log(dt)
        dt.ajax.reload()
    }

    onMounted(() => {
        $("tbody").on("click", ".edit-deal", function () {
            var slug = $(this).data("slug");
            router.push({ name: 'editDeal', params: { slug: slug } })
        });


        $("tbody").on("click", ".change-status", function (e) {
            e.preventDefault();
            let slug = $(this).data("slug");
            let status = $(this).data("status");
            updateDealStatus(slug,status)
        });

        // datatable api
        dt = table.value.dt;
    })

</script>

<style>
/* Icon size */
.edit-deal img{
    width:12px
}
</style>
<style scoped>
@import "../../../assets/css/dashboard/dataTables.css";
</style>