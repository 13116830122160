<template>
    <AppContainer>
        <Deals/>
    </AppContainer>
</template>

<script>
import AppContainer from '@/components/containers/DashboardContainer.vue'
import Deals from '@/components/dashboard/deals/Deals.vue'
export default {
  components: {
    AppContainer,
    Deals
  }
}
</script>